<template>
  <WithSpinner :isFetching="isFetching">
    <div
      v-if="product"
      class="column"
    >
      <div class="lab">
        <div class="lab__content container">
          <div class="lab__info">
            <div class="lab__subtitle">
              {{ t("labs.virtualLab", { name: product.subsection.name }) }}
            </div>
            <div class="lab__title">
              {{ product.name }}
            </div>
          </div>
          <div
            v-if="product.has_workbook"
            class="lab__workbook"
          >
            <IconWorkbook class="lab__workbook-icon" />
            {{ t("labs.workbook") }}
          </div>
        </div>
        <picture>
          <img
            :src="product.app_img?.original"
            :alt="product.name"
            class="lab__background"
          />
        </picture>
        <div
          v-if="canLaunch"
          class="lab__subscribe-wrap"
        >
          <div class="lab__subscribe">
            <LaboratoryDetails
              v-if="showLicenseDetails"
              :license="license"
            />
            <VButton
              color="success"
              class="lab__btn wide"
              @click="handleClickApp"
            >
              {{ t("labs.launch") }}
              <CountBadge
                v-if="isMobileConst"
                color="success"
              >
                <IconQuestion />
              </CountBadge>
            </VButton>
          </div>
        </div>
        <div
          v-else
          class="lab__subscribe-wrap"
        >
          <div class="lab__subscribe">
            <h2
              v-if="product.sale_for_user"
              class="lab__subscribe-text"
            >
              {{ t("labs.oneRubSale") }}
            </h2>
            <h2
              v-else
              class="lab__subscribe-text"
            >
              {{ t("labs.subscriptionInfo") }}
            </h2>
            <VButton
              appearance="solid"
              size="wide"
              color="primary"
              class="lab__btn"
              @click="handleSubscribeClick"
            >
              {{ t("labs.subscribe") }}
            </VButton>
            <VButton
              v-if="product.demo_mode"
              color="success"
              class="lab__btn"
              :class="{ 'mobile': isMobileConst }"
              :disabled="!Boolean(product.demo_counter) && !isMobileConst"
              @click="isMobileConst ? handleClickApp() : handleClick()"
            >
              {{ t("labs.demo") }}
              <CountBadge color="success">
                <IconQuestion v-if="isMobileConst" />

                <Tooltip
                  v-else
                  position="top-left"
                  color="success-fill"
                  :message="demoCounterTooltipText"
                >
                  {{ product.demo_counter }}
                </Tooltip>
              </CountBadge>
            </VButton>
          </div>
        </div>
      </div>
      <div
        class="labs__terms"
        v-html="product.description"
      />
    </div>
  </WithSpinner>
  <MobileModal
    :isOpen="isOpen"
    @close="isOpen = false"
  />
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { names } from '@/constants/routes';
import {
  getStorageItem,
  setStorageItem,
  catchErrorShow,
  pluralNoun,
  isMobile,
} from '@/utils';
import { useEventListener } from '@/libraries/useEventListener';
import { loginUser } from '@/services/userActions';

import WithSpinner from '@/components/WithSpinner.vue';
import CountBadge from '@/components/CountBadge.vue';
import VButton from '@/components/VButton.vue';
import Tooltip from '@/components/Tooltip.vue';
import IconQuestion from '@/components/icons/Question.vue';
import IconWorkbook from '@/components/icons/Workbook.vue';

import { getUserSubscriptions } from '@/router/Subscriptions/services';
import { SubscribeUser } from '@/router/Subscriptions/types';
import dayjs from '@/utils/dayjs';
import services from '../services';

import LaboratoryDetails from './components/Details.vue';
import MobileModal from './components/MobileModal.vue';
import { LicenseState } from './constants';
import { License } from './types';


const { t } = useI18n();
const router = useRouter();

const props = defineProps({
  id: {
    type: Number,
    default: 0,
  },
});

const isMobileConst = isMobile();

const store = useStore();

const isFetching = ref<boolean>(true);
const product = ref<License | null>(null);
const isOpen = ref<boolean>(false);
const license = ref<SubscribeUser | null>(null);

const isUser = computed<boolean>(() => store.getters.isAuthed);

const canLaunch = computed(() => product.value?.active_for_user);

const hasActiveLicenseState = computed(
  () => Boolean(product.value?.license_state)
);

const hasOrgLicense = computed(
  () => product.value?.license_state === LicenseState.Organization
);

const isLicenseExpired = computed(
  () => {
    const now = dayjs();
    const expires = (license.value?.date_expiration
      ? dayjs(license.value.date_expiration)
      : null);
    const diff = expires
      ? expires.diff(now, 'day')
      : 0;
    return diff < 0;
  }
);

const showLicenseDetails = computed(
  () => hasActiveLicenseState.value
    && license.value
    && !hasOrgLicense.value
    && !isLicenseExpired.value
);

const demoCounterTooltipText = computed(
  () => t(
    'youHaveNLaunchesLeft',
    {
      nn: product.value?.demo_counter || 0,
    },
    pluralNoun(product.value?.demo_counter || 0),
  )
);

const handleSubscribeClick = () => {
  // Get from local storage and covert into Set for unique values
  const basket = new Set(getStorageItem<number[]>('basket') || []);
  if (product.value?.offer.id) {
    // Add offer id of product to select them in shop
    basket.add(product.value.offer.id);
  }
  // Update basket
  setStorageItem('basket', Array.from(basket.values()));

  // Go into shop
  router.push({ name: names.shop });
};

const handleClick = async () => {
  if (isUser.value) {
    try {
      if (!product.value?.demo_counter) {
        throw new Error();
      }
      const { url } = await services.getLaboratoryDemoLink(props.id);
      window.open(url, '_blank');
    } catch (err) {
      catchErrorShow(err, t);
    }
    return;
  }
  return loginUser();
};

const handleClickApp = async () => {
  if (isMobileConst) {
    isOpen.value = true;
  }
  try {
    const { url } = await services.getAppLink(props.id);
    window.open(url, '_blank');
  } catch (error) {
    console.error(error);
  }
};

const refreshProductData = async () => {
  if (document.visibilityState === 'visible') {
    const refreshProduct = await services.getLaboratoryById(props.id);
    product.value = refreshProduct;
  }
};

const launchFunction = async () => {
  isFetching.value = true;
  try {
    let subscriptions;

    const requests = [];
    if (isUser.value) {
      requests.push(services.getLaboratoryById(props.id));
      requests.push(getUserSubscriptions());
    } else {
      requests.push(services.getPublicLaboratoryById(props.id));
    }

    [product.value, subscriptions] = await Promise.all(requests);

    license.value = subscriptions?.find(
      (
        subscription: SubscribeUser
      ) => subscription.offer_id === product.value?.offer.id
    ) || null;
  } catch (err) {
    catchErrorShow(err, t);
  }
  isFetching.value = false;
};

launchFunction();

useEventListener(document, 'visibilitychange', refreshProductData);
</script>

<style lang="scss">
@import "@/assets/style/include.scss";

.lab {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-between;
  position: relative;
}

.lab__background {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.lab__content {
  position: relative;
  display: flex;
  gap: 15px;
  flex-direction: column;
  align-items: end;
  padding-top: 50px;
  padding-bottom: 250px;
}

.lab__info {
  padding: 10px 44px;
  background: $color-white;
  box-shadow: 4px 4px 0 $color-primary-shadow;
  width: fit-content;
}

.lab__workbook {
  display: flex;
  width: fit-content;
  padding: 6px 12px;
  font-size: 22px;
  background: $color-peach;
  box-shadow: 4px 4px 0 $color-primary-shadow;
  color: $color-white;
  gap: 12px;
  &-icon {
    font-size: 28px;
  }
}

.lab__subtitle {
  color: $color-primary-darkest;
  font-size: 22px;
  padding-bottom: 10px;
  border-bottom: 1px solid $color-primary-lightest;
}

.lab__title {
  font-size: 44px;
  font-weight: 700;
  color: $color-primary-darkest;
  text-align: right;
}

.lab__subscribe-wrap {
  background: var(--color-grey-laboratory);

  padding: 40px;
  z-index: 1;
}

.lab__subscribe {
  align-items: center;
  display: flex;
  gap: 30px;
  justify-content: center;
  margin: 0 auto;
  max-width: 1000px;
  position: relative;
}

.lab__btn {
  position: relative;
  font-weight: 700;
  height: 40px;
}

.lab__btn_green {
  padding: 6px 28px;
  border-radius: 35px;
  border: 2px solid $color-success-lightest;
  background: $color-success;
  color: $color-white;
  transition: background $transition-speed;

  @include hover-focus() {
    background: $color-success-lightest;
  }
}

.lab__subscribe-text {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  font-size: 20px;
  max-width: 560px;
}

.lab__sale {
  display: inline-flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 1;
  position: relative;
}

.lab__sale-price {
  font-size: 12px;
  text-decoration: line-through;
}

.lab__sale-alert {
  background: $color-warning;
  border-radius: 30px;
  bottom: 105%;
  font-size: 14px;
  padding: 4px 7px;
  position: absolute;
}

.labs__terms {
  max-width: 1000px;
  margin: 40px auto;
  & p,
  & li,
  & ul,
  & ol {
    margin: revert;
  }

  ul, ol {
    padding-inline-start: 40px;
  }
}

.labs__modal-title {
  font-size: 26px;
  font-weight: 400;
  text-align: center;
}

.labs__modal-content {
  display: grid;
  gap: 10px;
  justify-content: center;
}


@include media-breakpoint-down("md") {
  .lab__info {
    margin: 0;
    box-shadow: none;
    padding: 0;
    text-align: center;
    width: 100%;
    padding: 10px;
  }
  .lab__subtitle {
    font-size: 16px;
    border-bottom: none;
    padding-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .lab__title {
    font-size: 20px;
    text-align: center;
  }
  .lab__content {
    padding: 0;
  }
  .lab__background {
    position: relative;
  }
  .lab__subscribe-wrap {
    padding: 20px;
  }
  .lab__subscribe {
    display: grid;
    grid-template-columns: minmax(200px, 250px) min-content;
    gap: 20px;
  }

  .lab__subscribe-text {
    font-weight: 400;
    grid-column: -1/1;
    font-size: 16px;
    text-align: center;
  }

  .labs__terms {
    padding: 20px;
    margin: 0px;
    & > p:first-child {
      margin-top: 0px;
    }
  }

  .btn_primary {
    background-color: $color-blue-menu;
  }

  .lab__btn.wide {
    grid-column: -1/1;
  }
}

</style>
