import { roles, allRoles, orgAccessRoles, suRoles } from './roles';

export const names = {
  profile: 'Profile',
  shop: 'Shop',
  invoices: 'Invoices',
  laboratories: 'Laboratories',
  laboratory: 'Laboratory',
  subscribe: 'Subscriptions',
  alerts: {
    closed: 'SessionClosed',
  },
  errorsMock: 'ErrorsMock',
};

export const paths = {
  profile: '/profile/:type?',
  shop: '/shop',
  invoices: '/invoices',
  laboratories: '/',
  laboratory: '/laboratory/:id',
  subscribe: '/subscriptions',
  alerts: {
    closed: '/session-closed',
  },
  errorsMock: '/errors-mock',
};

export const permissions = {
  profile: [
    roles.admin,
    roles.moderator,
    roles.marketer,
    roles.student,
    roles.teacher,
    roles.orgOwner,
    roles.orgModerator,
  ],
  laboratories: allRoles,
  laboratory: allRoles,
  alerts: allRoles,
  subscribe: [roles.student, roles.teacher, roles.marketer],
  shop: [
    roles.marketer,
    roles.student,
    roles.teacher,
    roles.guest,
  ],
  price: [
    roles.marketer,
    ...suRoles,
  ],
  invoices: [
    roles.orgOwner,
    roles.orgModerator,
  ],
  administration: {
    root: orgAccessRoles,
  },
};

export default {
  names,
  paths,
  permissions,
};
