<template>
  <footer class="footer">
    <div class="container flex">
      <div class="footer__doc">
        <a
          v-if="showPdfLink"
          :href="documents.publicOffer"
          target="_blank"
          class="footer__link"
        >
          {{ t("footer.publicOffer") }}
        </a>
        <a
          v-if="showPdfLink"
          :href="documents.termsOfUse"
          target="_blank"
          class="footer__link"
        >
          {{ t("footer.termsOfUse") }}
        </a>

        <a
          v-if="currentSupportLink"
          target="_blank"
          class="footer__link"
          :href="currentSupportLink"
        >
          {{ t("footer.support") }}
        </a>
        <button
          type="button"
          class="footer__link"
          @click="handleModalToggle(true)"
        >
          {{ t("footer.systemRequirements") }}
        </button>
        <a
          v-if="showPdfLink"
          :href="documents.personalDataProcessingRules"
          target="_blank"
          class="footer__link"
        >
          {{ t("footer.personalDataProcessingRules") }}
        </a>
      </div>

      <div class="footer__vizex">
        <img
          class="footer__logo"
          src="/img/vizex-logo.svg"
          width="148"
          height="35"
          alt="Vizex"
        />
        <div class="footer__copyright">
          {{ currentCopyright(t, date) }}
        </div>
      </div>

      <SystemRequirementsModal
        :isOpen="isModalOpen"
        @close="handleModalToggle($event)"
      />
    </div>
  </footer>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import useDocumentLinks from '@/composition/useDocumentLinks';
import dayjs from '@/utils/dayjs';

import { currentCopyright, currentSupportLink } from '@/utils';

import SystemRequirementsModal from '@/components/SystemRequirmentsModal.vue';

const { t } = useI18n();

const isModalOpen = ref<boolean>(false);

const date = dayjs().format('YYYY');
const documents = useDocumentLinks();

// TODO: show en and fr document links
// Ссылки в футере временно скрыты для международного портала
const showPdfLink = process.env.VUE_APP_TYPE !== 'international';

const handleModalToggle = (flag: boolean) => {
  isModalOpen.value = flag;
};
</script>

<style scoped lang="scss">
@import "@/assets/style/include.scss";

.footer {
  background: $color-white;

  &__doc {
    display: grid;
    gap: 0.5rem;
    grid-template-columns: 1fr 1fr 1fr;

    @include media-breakpoint-down("md") {
      grid-template-columns: 1fr;
    }

    @include media-breakpoint-down("sm") {
      gap: 0.875rem;
    }
  }

  &__link {
    color: var(--color-blue-darkest-footer);
    cursor: pointer;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    text-decoration: underline;

    @include hover-focus() {
      text-decoration: none;
    }

    @include media-breakpoint-down("sm") {
      text-align: center;
      font-weight: 400;
    }
  }

  &__vizex {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;

    @include media-breakpoint-down("sm") {
      align-items: center;
    }
  }

  &__copyright {
    @include media-breakpoint-down("sm") {
      font-size: 12px;
    }
  }
}

.flex {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 22px;
  padding-top: 32px;

  @include media-breakpoint-down("sm") {
    flex-direction: column;
    gap: 32px;
    justify-content: center;
  }
}
</style>
